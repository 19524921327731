import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import Select from 'react-select'

import './invitation.scss'

import Layout from '../components/Layout'

import {
  isBrowser,
  hasPermission
} from '../components/Auth'

import { onlyNumber } from '../utilities/only-number'
import { getForces } from '../services/api/forces'
import { postInvitation } from '../services/api/invitations'
import { Context } from '../Context'

export default function InvitationPage () {
  const { isAuth } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-invitation'>
        Cargando...
      </Layout>
    )
  }

  // create_invitation (id=116)
  if (!hasPermission(116)) {
    return (
      <Layout className='page-invitation'>
        <h1>Acceso restringido</h1>
      </Layout>
    )
  }

  const [forces, setForces] = useState([])
  const [formValues, setFormValues] = useState({
    fullname: '',
    email: '',
    phone: '',
    force_id: 0
  })

  // get forces from backend
  useEffect(() => {
    getForces().then(res => {
      if (res.status === 200) {
        setForces(res.data.map(i => {
          return {
            value: i.id,
            label: i.name
          }
        }))
      }
    })
  }, [])

  function onSubmit (e) {
    e.preventDefault()
    if (!formValues.force_id) {
      toast.error('La fuerza es requerida')
      return
    }

    postInvitation({ body: formValues })
      .then(res => {
        if (res.status === 200) {
          toast.success(res.data.message)
          navigate('/invitation-list/')
        }
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
  }

  function onChange (e) {
    const name = e.target.name
    let value = e.target.value

    if (name === 'phone') {
      value = onlyNumber(value)
    }

    setFormValues({ ...formValues, [name]: value })
  }

  return (
    <Layout className='page-invitation'>
      <h1>Invitar para registro de usuario</h1>

      <form
        onSubmit={onSubmit}
      >
        <label>
          <div>NOMBRE COMPLETO:</div>
          <input
            required
            name='fullname'
            type='text'
            value={formValues.name}
            onChange={onChange}
            placeholder='NOMBRE'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>TELÉFONO:</div>
          <input
            required
            name='phone'
            type='text'
            value={formValues.phone}
            onChange={onChange}
            placeholder='TELÉFONO'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>EMAIL:</div>
          <input
            required
            name='email'
            type='email'
            value={formValues.email}
            onChange={onChange}
            placeholder='EMAIL'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>EMAIL ALTERNATIVO:</div>
          <input
            name='email2'
            type='email'
            value={formValues.email2}
            onChange={onChange}
            placeholder='EMAIL ALTERNATIVO'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label style={{ width: '100%' }}>
          <div>FUERZA</div>
          <div style={{ display: 'flex' }}>
            <Select
              options={forces}
              onChange={option => setFormValues({
                ...formValues,
                force_id: option.value
              })}
              value={forces.find(i => i.value === formValues.force_id)}
              placeholder='Fuerza'
              className='select'
              classNamePrefix='select'
            />
          </div>
        </label>

        <button type='submit'>ENVIAR INVITACIÓN</button>
      </form>
    </Layout>
  )
}
